<template>
  <div class="section header-section">
    <div class="">
      <div class="row">
        <div class="col s12 m6 white-text">
          <h3>ACMI AUTOMATION MEXICO</h3>
          INNOVATING SOLUTIONS
        </div>
        <div class="col s12 m6" style="color: antiquewhite;">
          ¿TIENES ALGUNA URGENCIA? LLAMANOS AL
          <a href="tel:+(52)8009990695" target="_blank" style="color: azure; font-size: 20px;">
            800 999 0695
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/custom.scss";
.header-section {
  background: -prefix-linear-gradient(left, #0cadf5, $blueFinal);
  background: linear-gradient(to right, #0cadf5, $blueFinal);
  font-size: $defaultFont;
  font-family: "LEMONMILK";
  padding: 40px;
  font-size: 15px;
  .row {
    margin-bottom: 0 !important;
  }
  .col {
    font-family: "LEMONMILK";
  }
  h3 {
    margin: 0;
    font-size: 30px;
    font-family: "LEMONMILK";
  }
  .right-align {
    margin-top: 1.46rem;
  }
}
</style>
