<template>
  <div class="section" id="servicios">
    <div class="container">
      <div class="row">
        <div class="col s12 center">
          <h3>Servicios</h3>
          <img
            src="@/assets/img/gear.gif"
            alt="Servicios"
            class="responsive-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/custom.scss";
#servicios {
  background-color: #f6f8f9;
  h3 {
    color: $blueInitial;
  }
}
</style>
