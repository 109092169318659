<template>
  <div class="parallax-container" id="inicio">
    <div class="section no-pad-bot"></div>
    <div >
    
      <img class="img-ico" src="@/assets/img/icono_247_acmi.png" alt="">
      <h1>Somos tu mejor aliado para obtener cualquier requerimiento de la industria.</h1>
      <h2>Material urgente y obsoleto. <br>
        Rapidez, calidad y seguridad. <br>
        Envios a todo el pais.</h2>
            
        <div id="play-button" class="play-icon" style="color: white; font-size: 15px; width: 100px;"> <span class="play-text">Ver video</span></div>
        <a class="wa waves-effect waves-light btn" href="https://api.whatsapp.com/send?phone=524428490717')" 
            target="_blank" style="margin-top: 15px;" >
             Solicita por What'sApp
        </a>
  
    
<div id="video-container">
    <video id="background-video" muted loop>
      <source src="@/assets/img/acmi.mp4" type="video/mp4">
    </video>
    <div class="overlay"></div>
  
  </div>
  <div id="video-player" class="hidden">
    <video id="foreground-video" controls>
      <source src="@/assets/img/acmi.mp4" type="video/mp4">
    </video>
    <button id="close-button" class="close-button">&times;</button>
  </div>

     <!--  <img class="fondo-acmi" src="@/assets/img/parallax/acmi_principal_industria.png" alt="Inicio" /> -->
    </div>
  </div>
</template>



<style lang="scss" scoped>
@import "@/assets/styles/custom.scss";

.parallax-container a{
  position: absolute;
  top: 75%;
  left: 7%;
  padding: auto;
  width:20%;
  background: rgb(224,224,224);
  background: linear-gradient(90deg, rgba(224,224,224,1) 0%, rgba(221,240,255,1) 50%, rgba(169,219,255,1) 100%);
  color: rgb(19, 14, 66);
  font-family: sans-serif;
  font-weight: 800;
  font-size: 15px;
  font-style: italic;
}

.parallax-container a:hover{
  position: absolute;
  top: 75%;
  left: 7%;
  padding: auto;
  width:20%;
  background: rgb(196, 175, 175);
  background: linear-gradient(90deg, rgb(0, 195, 255) 0%, rgb(82, 146, 196) 50%, rgba(9, 55, 88, 0.425) 100%);
  color: rgb(255, 255, 255);

  font-family: sans-serif;
  font-weight: 800;
  font-size: 15px;
  font-style: italic;
}
.parallax-container h1{
  position: absolute;
  top: 30%;
  left: 7%;
  width: 70%;
  color: white;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 80px;
  font-style: italic;
  pointer-events: none;
}
.parallax-container h2{
  position: absolute;
  top: 63%;
  left: 7%;
  width: 70%;
  color: white;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-style: italic;
  text-align: left;
}
 .img-ico {

  left: 6%;
  background: transparent;
    position: absolute;
    top: 130px;
 }
.parallax-container{
  height: 900px;
}



@media (max-width: 1250px) {
  .parallax-container h1{
  font-size: 70px;
  width: 80%;
}

}


@media (max-width: 1350px) {

#play-button {
  z-index: 0;
  position: absolute;
  top: 70%;
  left: 40% !important;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  cursor: pointer;
}

}

@media (max-width: 1000px) {

#play-button {
  z-index: 0;
  position: absolute;
  top: 73% !important;
  left: 60% !important;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  cursor: pointer;
}

}

@media (max-width: 780px) {

#play-button {
  z-index: 0;
  position: absolute;
  top: 73% !important;
  left: 70% !important;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  cursor: pointer;
}

}

@media (max-width: 600px) {

#play-button {
  z-index: 0;
  position: absolute;
  top: 25% !important;
  left: 80% !important;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  cursor: pointer;
}

}



@media (max-width: 768px) {
  .parallax-container h1{
  font-size: 40px;
  width: 80%;
}


.fondo-acmi img{
transform: translateX(-600px);
}

.parallax-container a{
  position: absolute;
  top: 80%;
  left: 7%;
  padding: auto;
  width: 70%;
  background: rgb(224,224,224);
background: linear-gradient(90deg, rgba(224,224,224,1) 0%, rgba(221,240,255,1) 50%, rgba(169,219,255,1) 100%);
  color: rgb(19, 14, 66);
  font-family: sans-serif;
  font-weight: 800;
  font-size: 15px;
  font-style: italic;
}
.parallax-container a:hover{
  position: absolute;
  top: 80%;
  left: 7%;
  padding: auto;
  width: 70%;
  background: rgb(196, 175, 175);
background: linear-gradient(90deg, rgb(0, 195, 255) 0%, rgb(82, 146, 196) 50%, rgba(9, 55, 88, 0.425) 100%);
  color: rgb(255, 255, 255);

  font-family: sans-serif;
  font-weight: 800;
  font-size: 15px;
  font-style: italic;
}
.img-ico {

left: 2%;
width: 40%;
background: transparent;
  position: absolute;
  top: 150px;
}
}
.parallax-container .parallax img {
    opacity: 0;
    position: absolute;
    left: 0%;
    bottom: 0px;
    min-width: 100%;
    min-height: 100%;
    transform: translate3d(0, 0, 0);
    transform: translateX(-50%);
}

@media (min-width: 768px) and (max-width: 1600px){
  .parallax-container a{
  position: absolute;
  top: 90%;
  left: 7%;
  padding: auto;
  width: 35%;
  background: rgb(224,224,224);
  background: linear-gradient(90deg, rgba(224,224,224,1) 0%, rgba(221,240,255,1) 50%, rgba(169,219,255,1) 100%);
  color: rgb(19, 14, 66);
  font-family: sans-serif;
  font-weight: 800;
  font-size: 15px;
  font-style: italic;
}
.parallax-container h2{
  position: absolute;
  top: 75%;
  left: 7%;
  width: 70%;
  color: white;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-style: italic;
  text-align: left;
}

}

#video-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

#background-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.play-text {
  color: white;
  font-weight: bold;
  position: relative;
  top: -16px;
  left: 10px;
}
#play-button {
  z-index: 0;
  position: absolute;
  top: 70%;
  left: 30%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  cursor: pointer;
}

#video-player {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  background-color: white;
}


#foreground-video {
  z-index: 10;
  width: 100%;
  height: auto;

}

.close-button {
  position: absolute;
  top: 5px;
  color: white;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  background-color: transparent;
}

@keyframes subtleMove {
  0%, 100% {
    transform: translate(-50%, -50%) translateX(-7px);
  }
  50% {
    transform: translate(-50%, -50%) translateX(7px);
  }
}

.play-icon {
  /* Código existente... */
  animation: subtleMove 2s infinite;
}

.play-icon {
  position: relative;
  z-index: 0;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.5rem 0 1.5rem 2.5rem;
  border-color: transparent transparent transparent white;
  cursor: pointer;
 
}
.hidden {
  display: none;
}

@media (max-width: 768px) {
  #video-player {
    top: 19%;
    left: 5%;
    width: 89%;
    height: 21%;
  }

  #play-button {
  z-index: 0;
  position: absolute;
  top: 70%;
  left: 30%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  cursor: pointer;
}

}

.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>



<script>
// components
let M = require("materialize-css");

export default {
  name: "Index",

  mounted() {
    // materialize
    let elems = document.querySelectorAll(".parallax");
    M.Parallax.init(elems);
  },
};

document.addEventListener('DOMContentLoaded', function () {
  const backgroundVideo = document.getElementById('background-video');
  const playButton = document.getElementById('play-button');
  const videoPlayer = document.getElementById('video-player');
  const foregroundVideo = document.getElementById('foreground-video');
  const closeButton = document.getElementById('close-button');

  backgroundVideo.play();

  playButton.addEventListener('click', function () {
    videoPlayer.classList.remove('hidden');
    backgroundVideo.pause();
    foregroundVideo.play();
  });

  closeButton.addEventListener('click', function () {
    videoPlayer.classList.add('hidden');
    backgroundVideo.play();
    foregroundVideo.pause();
    foregroundVideo.currentTime = 0;
  });

  foregroundVideo.addEventListener('ended', function () {
    videoPlayer.classList.add('hidden');
    backgroundVideo.play();
  });
});
</script>
