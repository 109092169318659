<template>
  <div class="container" id="nosotros">
    <div class="section">
      <div class="row">
        <div class="col s12 m4">
          <div class="icon-block">
            <h2 class="center brown-text">
              <i class="material-icons medium">flash_on</i>
            </h2>
            <h3 class="center">Misión</h3>
            <p>
              La misión de ACMI es lograr y garantizar el éxito de nuestros
              clientes a través de la consultoría, desarrollo y cumplimiento de
              sus necesidades o requerimientos en los diversos sectores de la
              industria, soportados por una organización comprometida con el
              desarrollo social y el medio ambiente.
            </p>
          </div>
        </div>
        <div class="col s12 m4">
          <div class="icon-block">
            <h2 class="center brown-text">
              <i class="material-icons medium">group</i>
            </h2>
            <h3 class="center">Qué hacemos</h3>
            <p>
              ACMI AUTOMATION MEXICO es una empresa mexicana dedicada a la
              consultoría y al desarrollo de soluciones tecnológicas integrales,
              así como la comercialización de diversos insumos industriales.<br /><br />
              En ACMI nos preocupamos por desarrollar el conocimiento de los
              procesos y cumplir con los requerimientos de nuestros clientes
              convirtiéndonos en el socio estratégico ideal que les aportara
              valor mediante la innovación, calidad y servicio.
            </p>
          </div>
        </div>
        <div class="col s12 m4">
          <div class="icon-block">
            <h2 class="center brown-text">
              <i class="material-icons medium">settings</i>
            </h2>
            <h3 class="center">Visión</h3>
            <p>
              ACMI será una empresa líder en el desarrollo de soluciones
              tecnológicas que nos convertirá en el socio estratégico ideal de
              nuestros clientes mediante la innovación tecnológica y la búsqueda
              de la excelencia organizacional.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/custom.scss";
#nosotros {
  font-size: $defaultFont;
  h2,
  h3 {
    margin: 0rem;
  }
  p{
    text-align: justify;
  }
}
</style>
