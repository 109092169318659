const state = {
  form: {
    search: null,
  },
  status: null,
  details: {
    products: [],
  },
};

export default state;
